import logo from '../assets/logo.png';

const Header = () => {
    return (
        <div>
            <div class="headersLands">
                <div class="headers2">

                    <div class="logo" onClick={() => window.location.href = '/'}>
                        <img class="logoPic" src={logo} />
                    </div>

                    <div class="right">
                        <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                        <div class="navDiv">Services</div>
                        <div class="navDiv">Contact Us</div>
                        <div class="navDiv">Learning</div>
                        <div class="navDiv" onClick={() => window.location.href = 'socials'}>Socials</div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Header;