/* eslint-disable no-undef */
import '../App.css';
import '../counter.css';
import React, { useEffect, useMemo, useState } from 'react';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork, useWaitForTransaction } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther, webSocket } from 'viem';
import Countdown from 'react-countdown';
import { bsc } from 'wagmi/chains'
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import logo from '../assets/logo.png';


const ops = () => {
    window.open("https://opensea.io/collection/yanus-nft-gensis-pass");
}

const tweet = () => {
    window.open("https://x.com/yanusmobile");
}

const homeLink = () => {
    window.open("#");
}

const Home = () => {

    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [_navbarOpen, set_navbarOpen] = useState(0);

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }
    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    const zoomFactor = 1 + (scrollY / 100); // Adjust the value to control the zoom speed

    return (
        <div className="allWrap">
            {/*<div class="page-loader2">
                <div class="parent-container">
                    <img className='logoLoad' src={logo} />
                </div>
            </div>*/}
            <div className="light">

                <div class="headers">

                    <div class="headers2">

                        <div class="logo" onClick={() => window.location.href = '/'}>
                            <img class="logoPic" src={logo} />
                        </div>

                        <div class="right">
                            <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                            <div class="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                            <div class="navDiv" onClick={() => window.location.href = 'services'}>Contact Us</div>
                            <div class="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                            <div class="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                        </div>

                    </div>

                </div>

                {_navbarOpen < 1 ?
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                    </div>) :
                    (<div class="miniBarMain">
                        <img className='logoPic' onClick={() => window.location.href = '/'} src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                    </div>)}

                {_navbarOpen > 0 ?
                    (<div class="littleNav">
                        <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                        <div class="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                        <div class="navDiv" onClick={() => window.location.href = 'services'}>Contact Us</div>
                        <div class="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                        <div class="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                    </div>) : null}

                 <img
                    className="mainBg"
                    src='https://img-471.pages.dev/imgs/doorway.jpg'
                    alt="Main Background Image"
                    style={{ transform: `scale(${zoomFactor})` }}
                />
            </div>
        </div> 
    );
}

export default Home;